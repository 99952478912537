import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'
  import { FormattedMessage} from 'react-intl';
  import metodImage from '../assets/img/metod.png';

export default function Service(fixed) {
  const wpNumber = process.env.REACT_APP_BUTTOM_PHONE;


  const lang = localStorage.getItem('lang');

  const text = lang === 'es-ES' ? 'Estoy%20interesado%20me%20podian%20enviar%20mas%20informacion?' : 'I%20am%20interested%20in%20more%20information%20on%20you';
  
  const refWp = "https://wa.me/"+wpNumber+"?&text="+text;

    return (
        <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h2' style={{ fontSize: '2em' }}>
              <FormattedMessage id="body.seccion1.title" defaultMessage={""} />
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <FormattedMessage id="body.seccion1.paragraph.first" defaultMessage={""} />
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <FormattedMessage id="body.seccion1.subtitle1" defaultMessage={""} />
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <FormattedMessage id="body.seccion1.paragraph.second" defaultMessage={""} values={
                {
                  'link-to-wiki': (...chunks) => (
                    <a href="https://es.wikipedia.org/wiki/Metodolog%C3%ADa_de_desarrollo_de_software" target="_blank">{chunks}</a>
                    )
                }
              } />
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <FormattedMessage id="body.seccion1.subtitle2" defaultMessage={""} />
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <FormattedMessage id="body.seccion1.paragraph.third" defaultMessage={""} values={
                {
                  'link-to-wiki': (...chunks) => (
                    <a href="https://es.wikipedia.org/wiki/Scrum_(desarrollo_de_software)" target="_blank">{chunks}</a>
                    )
                }
              } />
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <FormattedMessage id="body.seccion1.subtitle3" defaultMessage={""} />
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <FormattedMessage id="body.seccion1.paragraph.fourth" defaultMessage={""} />
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <img src={metodImage} style={{
              width: '150%',
              borderRadius: '55%',

            }} alt="Image Metod" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            
            <Button as='a' size='huge' href={refWp} target="_blank"><FormattedMessage id="body.seccion1.button.contact" defaultMessage={""} /></Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )

}